import Slider from "react-slick";
import { useRef } from "react";
import about_bg from "../assets/video_bg.jpg";
import play from "../assets/play.svg";
import testimonial_one from "../assets/testimonial_one.jpg";
import testimonial_two from "../assets/testimonial_two.jpg";
import homepage_instructor from "../assets/images/instructors/homepage_instructor.png";
import user from "../assets/users.png";
import { useEffect } from "react";
import axios from "axios";
import logo from "../assets/logo.svg";
import { useState } from "react";
import loading from "../../images/common/loading.svg";
import defaultImage from "../../images/home/blocktwo.png";
import chuksfoundation from "../../images/home/chuksfound.png";
import iredesike from "../../images/home/iredesike.png";
import engineer from "../../images/home/engineers.png";
import ncs from "../../images/home/ncs.png";
import AOS from "aos";
import "aos/dist/aos.css";
import HomePageFaq from "./HomePageSections/HomePageFaq";
import Instructor from "./HomePageSections/Instructor";
import BrowseCategory from "./HomePageSections/BrowseCategory";
import Scholarship from "./HomePageSections/Scholarship";
import { ToastContainer, toast } from "react-toastify";
import LazyImageLoader from "../common/ImageLazyLoad";
import SummerModal from "./HomePageSections/SummerModal";

const Home = () => {
  useEffect(() => {
    AOS.init({
      // Global settings for AOS (optional)
      duration: 1000, // Duration of animations
      once: false, // Only animate elements once
    });
  }, []);

  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const toastAction = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const handleSubscribe = async () => {
    try {
      const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/subscribe`;

      const response = axios.post(endpointUrl, {
        email: email,
      });
      await toast.promise(
        response,
        {
          pending: "Submitting request",
          success: "Email Subscribed!",
          error: "Error adding email!",
        },
        toastAction
      );
      setEmail("");
    } catch (err) {
      console.log(err);
    }
  };

  let sliderRef = useRef(null);
  let sliderImage = useRef(null);
  const next = () => {
    sliderRef.slickNext();
    sliderImage.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
    sliderImage.slickPrev();
  };
  console.log(sliderRef);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingB = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [pricing, setPricing] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  // const [totalCourses, setTotalCourses] = useState([]);

  useEffect(() => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/all-courses`;

    axios
      .get(endpointUrl)
      .then((response) => {
        console.log(response.data);
        setPricing(response.data);
        // const totalEntries = Object.keys(response.data).length;
        // setTotalCourses(totalEntries);
        setLoadingPage(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <main className="main-area fix">
      <ToastContainer />
      <SummerModal />
      <section
        className="banner-area banner-bg"
        style={{
          backgroundImage: `url("assets/img/banner/banner_bg.jpg")`,
        }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner__content">
                <img
                  src="assets/img/banner/bshape_01.png"
                  alt="shape"
                  className="shape alltuchtopdown"
                />
                <img
                  src="assets/img/banner/bshape_02.png"
                  alt="shape"
                  className="shape"
                />
                <span
                  className="sub-title"
                  data-aos="fade-right"
                  data-aos-delay="200">
                  100% Satisfaction Guarantee
                </span>
                <h3
                  className="title tg-svg"
                  data-aos="fade-right"
                  data-aos-delay="400">
                  Upskilling
                  <span className="position-relative">
                    <span
                      className="svg-icon"
                      id="svg-2"
                      data-svg-icon="assets/img/icons/title_shape.svg"></span>{" "}
                    Minds
                  </span>{" "}
                  for Upscaled Productivity
                </h3>
                <p data-aos="fade-right" data-aos-delay="600">
                  UNICCON Academy provides a comprehensive learning experience.
                  It offers premium technologically-relevant, industry-valued,
                  and community-impact training in a variety of courses.
                  {/* Learn in demand and practical-oriented skills that meet
                  entrepreneurial and employment needs Uniccon Academy with its
                  expert trainers incorporates latest research, conducive
                  learning space, resources for an improved learning experience. */}
                </p>
                <div
                  className="banner__btn-wrap"
                  data-aos="fade-right"
                  data-aos-delay="800">
                  <div className="tg-button-wrap">
                    <a href="/pricing" className="btn tg-svg">
                      <span className="text">Enroll for a course</span>{" "}
                      <span
                        className="svg-icon"
                        id="svg-1"
                        data-svg-icon="assets/img/icons/btn-arrow.svg"></span>
                    </a>
                  </div>
                  <div className="banner__phone">
                    <i className="flaticon-phone-call"></i>
                    <div className="number-info">
                      <span>Have any Question?</span>
                      <h6 className="number">
                        <a href="tel:2349131574290">+2349131574290</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner__images">
                <img src={homepage_instructor} alt="img" className="main-img" />
                <img
                  src="assets/img/banner/bshape_03.png"
                  alt="shape"
                  className="shape"
                  data-aos="fade-down-right"
                  data-aos-delay="1200"
                />
                <img
                  src="assets/img/banner/bshape_04.png"
                  alt="shape"
                  className="shape"
                  data-aos="fade-right"
                  data-aos-delay="1200"
                />
                <img
                  src="assets/img/banner/bshape_05.png"
                  alt="shape"
                  className="shape"
                  data-aos="fade-down-left"
                  data-aos-delay="1200"
                />
                <div className="banner__fact">
                  <div className="banner__fact-item">
                    <div className="icon">
                      <i className="flaticon-group"></i>
                    </div>
                    <div className="info">
                      <span>Total Students</span>
                      <h4 className="count">15K</h4>
                    </div>
                  </div>
                  <div className="banner__fact-item">
                    <div className="icon">
                      <i className="flaticon-graduation-cap"></i>
                    </div>
                    <div className="info">
                      <span>Complete Graduation</span>
                      <h4 className="count">34K</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="brand-area">
        <div className="container">
          <div className="row brand-active">
            <div className="col">
              <div className="brand__item">
                <a href="#/">
                  <img src={chuksfoundation} alt="brand" />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand__item">
                <a href="#/">
                  <img src={iredesike} alt="brand" />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand__item">
                <a href="#/">
                  <img src={ncs} alt="brand" />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="brand__item">
                <a href="#/">
                  <img src={engineer} alt="brand" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="online-area tg-motion-effects">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7 " data-aos="fade-right">
              <div className="online__image-wrap">
                <div className="main-img">
                  <img src={about_bg} alt="img" />
                </div>
                <div className="enrolled__students position-absolute">
                  <p>
                    <span>1.2K+</span> Enrolled Students
                  </p>
                  <ul className="list-wrap">
                    <li>
                      <img src={user} alt="img" />
                    </li>
                    <li>
                      <img src={user} alt="img" />
                    </li>
                    <li>
                      <img src={user} alt="img" />
                    </li>
                    <li>
                      <img src={user} alt="img" />
                    </li>
                    <li>
                      <img src={user} alt="img" />
                    </li>
                    <li>
                      <img src={user} alt="img" />
                    </li>
                  </ul>
                </div>
                <img
                  src={play}
                  alt="img"
                  className="icons position-absolute tg-motion-effects6"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-5" data-aos="fade-left">
              <div className="online__content">
                <div className="section__title">
                  <span className="sub-title">Uniccon Academy Courses</span>
                  <h2 className="title">
                    Online Learning <br /> Courses Designed <br /> For Real Life
                  </h2>
                </div>
                <p>
                  The programme has a robustly designed curriculum for courses.
                  The curriculum caters to trainees at different skill levels.
                  Courses are graded from basic (beginner) to intermediate to
                  advanced. Experts in the various courses who wish to sharpen
                  their skills or engage in research are welcome to use the
                  facilities.
                </p>
                <div className="tg-button-wrap">
                  <a href="/pricing" className="btn tg-svg">
                    <span className="text">See All Categories</span>{" "}
                    <img
                      className="svg-icon"
                      id="online-btn"
                      src="assets/img/icons/btn-arrow.svg"
                      alt="img"
                    />
                  </a>
                </div>
                <img
                  src="assets/img/objects/online_04.png"
                  alt="shape"
                  className="right-shape position-absolute tg-motion-effects5"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="courses-area section-pt-120 section-pb-90">
        <div className="container">
          <div className="section__title-wrap">
            <div className="row align-items-end">
              <div className="col-lg-6">
                <div className="section__title text-center text-lg-start">
                  <span className="sub-title">Uniccon Academy Courses</span>
                  <h2 className="title tg-svg">
                    Our{" "}
                    <span className="position-relative">
                      <span
                        className="svg-icon"
                        id="svg-4"
                        data-svg-icon="assets/img/icons/title_shape.svg"></span>
                      Featured
                    </span>{" "}
                    Courses
                  </h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="courses__nav-active">
                  <button className="active" data-filter="*">
                    All Courses <span>New</span>
                  </button>
                  <button data-filter=".cat-one">Design</button>
                  <button data-filter=".cat-two">Analytics</button>
                  <button data-filter=".cat-three">Development</button>
                </div>
              </div>
            </div>
          </div>
          {loadingPage ? (
            <div className="tw-text-center tw-mt-80 tw-mb-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
              <img src={loading} alt="loader" className="tw-mb-5" />
              Loading...
            </div>
          ) : (
            <div className="row courses-active row-cols-1 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
              {pricing.map((course, index) => (
                <div
                  className="col grid-item cat-two cat-three"
                  data-aos="fade-right"
                  key={index}>
                  <div className="courses__item shine__animate-item">
                    <div className="courses__item-thumb  tw-max-h-[230px] tw-overflow-hidden">
                      <a
                        href={`/course/${course.slug}`}
                        className="courses__item-tag"
                        style={{ backgroundColor: "#04BC53" }}>
                        {course.title}
                      </a>
                      <a
                        href={`/course/${course.slug}`}
                        className="shine__animate-link">
                        <LazyImageLoader
                          src={
                            course.image
                              ? "https://apis.unicconacademy.com/storage/" +
                                course.image
                              : defaultImage
                          }
                          alt={course.title}
                        />
                      </a>
                    </div>
                    <div className="courses__item-content">
                      <ul
                        className="courses__item-meta list-wrap tw-flex lg:tw-justify-start tw-justify-center tw-items-center"
                        style={{ fontSize: "14px" }}>
                        <li>
                          <i className="flaticon-file"></i>05 Lessons
                        </li>
                        <li>
                          <i className="flaticon-timer"></i>
                          {course.duration}
                        </li>
                        <li>
                          <i className="flaticon-user-1"></i>Students
                        </li>
                      </ul>
                      <h5 className="title">
                        <a href={`/course/${course.slug}`}>{course.title}</a>
                      </h5>
                      <div className="courses__item-rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <span className="rating-count">(06)</span>
                      </div>
                      <div className="courses__item-bottom">
                        <div className="author">
                          <a href="#uit">
                            <img src={logo} alt="img" />
                          </a>
                          <a href="#uit">UIT</a>
                        </div>
                        <h5 className="price">
                          &#x20A6;
                          {course?.learning_paths?.Standard?.price
                            ? course?.learning_paths?.Standard?.price.toLocaleString()
                            : ""}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="courses__shapes">
          <div className="courses__shapes-item alltuchtopdown">
            <img src="assets/img/courses/course_shape01.png" alt="shape" />
          </div>
          <div className="courses__shapes-item alltuchtopdown">
            <img src="assets/img/courses/course_shape02.png" alt="shape" />
          </div>
        </div>
      </section>

      <Scholarship />

      <BrowseCategory />

      <section
        className="testimonial-area testimonial-bg section-py-120"
        style={{ background: `url("assets/img/bg/testimonial_bg.jpg")` }}>
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-lg-5 col-md-7 col-sm-9">
              <div className="testimonial__image-wrapper position-relative">
                <div className="testimonial__image-active">
                  <Slider
                    ref={(slider) => {
                      sliderImage = slider;
                    }}
                    {...settingB}>
                    <div key={1} className="testimonial__image-item">
                      <img src={testimonial_one} alt="img" />
                    </div>
                    <div key={2} className="testimonial__image-item">
                      <img src={testimonial_two} alt="img" />
                    </div>
                  </Slider>
                </div>
                <div className="testimonial__shapes">
                  <img
                    src="assets/img/objects/testi_shape01.svg"
                    alt="shape"
                    data-aos="fade-up-left"
                    data-aos-delay="300"
                  />
                  <img
                    src="assets/img/objects/testi_shape02.svg"
                    alt="shape"
                    data-aos="fade-up-right"
                    data-aos-delay="300"
                  />
                  <img
                    src="assets/img/objects/testi_shape03.svg"
                    alt="shape"
                    className="rotateme"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="testimonial__content-wrapper">
                <div className="section__title white-title mb-40">
                  <h2 className="title tg-svg">
                    What Our{" "}
                    <span className="position-relative">
                      <span
                        className="svg-icon"
                        id="svg-7"
                        data-svg-icon="assets/img/icons/title_shape.svg"></span>
                      Students
                    </span>
                    <br /> Say About Us
                  </h2>
                </div>
                <div className="testimonial__content-active">
                  <Slider
                    ref={(slider) => {
                      sliderRef = slider;
                    }}
                    {...settings}>
                    <div key={1} className="testimonial__content-item">
                      <div className="testimonial__content-icon">
                        <img src="assets/img/icons/quote.png" alt="img" />
                      </div>
                      <p>
                        “ Knowledge is not just about being teached but also
                        understanding the strength in developing oneself. This
                        is what I have learned from here.”
                      </p>
                      <div className="testimonial__content-avatar">
                        <h5 className="name">Chinenye Udeh</h5>
                        <span className="designation">UI Designer</span>
                      </div>
                    </div>
                    <div key={2} className="testimonial__content-item">
                      <div className="testimonial__content-icon">
                        <img src="assets/img/icons/quote.png" alt="img" />
                      </div>
                      <p>
                        “ I have been learning and it has been very beneficial
                        to me ”
                      </p>
                      <div className="testimonial__content-avatar">
                        <h5 className="name">Parker Robert</h5>
                        <span className="designation">
                          UI/UX Design Student
                        </span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <div className="testimonial__content-nav">
                  <button
                    type="button"
                    className="slick-prev"
                    onClick={previous}>
                    <i className="flaticon-chevron"></i>
                  </button>
                  <button type="button" className="slick-next" onClick={next}>
                    <i className="flaticon-chevron"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-area tw-py-28">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cta__wrapper">
                <div className="section__title white-title">
                  <h2 className="title tg-svg">
                    Best Onsite
                    <span className="position-relative">
                      <span
                        className="svg-icon"
                        id="svg-9"
                        data-svg-icon="assets/img/icons/title_shape.svg"></span>
                      Courses That
                    </span>{" "}
                    Will Elevate You
                  </h2>
                </div>
                <div className="cta__desc">
                  <p>
                    Step into a world of endless possibilities at our Academy,
                    where learning knows no boundaries
                  </p>
                </div>
                <div className="tg-button-wrap justify-content-center justify-content-md-end">
                  <a href="/pricing" className="btn white-btn tg-svg">
                    <span className="text">Become a Student</span>{" "}
                    <span
                      className="svg-icon"
                      id="cta-btn"
                      data-svg-icon="assets/img/icons/btn-arrow.svg"></span>
                  </a>
                </div>
                <img
                  className="object"
                  src="assets/img/objects/cta_shape01.svg"
                  style={{ left: "25px", top: "-35px" }}
                  alt="Object"
                  data-aos="fade-down"
                  data-aos-delay="400"
                />
                <img
                  className="object"
                  src="assets/img/objects/cta_shape02.svg"
                  style={{ right: "-20px", bottom: "-80px" }}
                  alt="Object"
                  data-aos="fade-up"
                  data-aos-delay="400"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Instructor />

      <HomePageFaq />

      {/* <section className="blog-area blog-bg-circle section-pt-205 section-pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-8">
              <div className="section__title text-center mb-40">
                <span className="sub-title">Always Smart To Hear News</span>
                <h2 className="title tg-svg">
                  Latest{" "}
                  <span className="position-relative">
                    <span
                      className="svg-icon"
                      id="svg-10"
                      data-svg-icon="assets/img/icons/title_shape.svg"
                    ></span>
                    News
                  </span>{" "}
                  & Blog
                </h2>
                <p className="desc">
                  Receive huge benefits with our lifetime Plumbing Receive huge
                  benefits with our lifetime Plumbing email address will be
                  shown
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-9">
              <div className="blog__post-item shine__animate-item">
                <div className="blog__post-thumb">
                  <a href="/#/pricing" className="shine__animate-link">
                    <img src="assets/img/blog/blog_thumb01.jpg" alt="img" />
                  </a>
                </div>
                <div className="blog__post-content">
                  <a href="#/" className="cat">
                    Education
                  </a>
                  <h4 className="title">
                    <a href="/#/pricing">
                      when aeunkno printer took galley of scrambled
                    </a>
                  </h4>
                  <ul className="list-wrap blog__post-meta">
                    <li>
                      <i className="flaticon-account"></i> by{" "}
                      <a href="#/">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-calendar-date"></i> 23 Aug, 2023
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-9">
              <div className="blog__post-item shine__animate-item">
                <div className="blog__post-thumb">
                  <a href="/#/pricing" className="shine__animate-link">
                    <img src="assets/img/blog/blog_thumb02.jpg" alt="img" />
                  </a>
                </div>
                <div className="blog__post-content">
                  <a href="#/" className="cat">
                    Course
                  </a>
                  <h4 className="title">
                    <a href="/#/pricing">
                      Full Stack GraphQL With Next.js And Vercel
                    </a>
                  </h4>
                  <ul className="list-wrap blog__post-meta">
                    <li>
                      <i className="flaticon-account"></i> by{" "}
                      <a href="#/">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-calendar-date"></i> 24 Aug, 2023
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-9">
              <div className="blog__post-item shine__animate-item">
                <div className="blog__post-thumb">
                  <a href="/#/pricing" className="shine__animate-link">
                    <img src="assets/img/blog/blog_thumb03.jpg" alt="img" />
                  </a>
                </div>
                <div className="blog__post-content">
                  <a href="#/" className="cat">
                    Skill Development
                  </a>
                  <h4 className="title">
                    <a href="/#/pricing">
                      What Leonardo Teach us About Web Design
                    </a>
                  </h4>
                  <ul className="list-wrap blog__post-meta">
                    <li>
                      <i className="flaticon-account"></i> by{" "}
                      <a href="#/">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-calendar-date"></i> 24 Aug, 2023
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="object"
          src="assets/img/objects/blog_shape01.png"
          width="97"
          style={{ left: "8%", top: "32%" }}
          alt="Object"
        />
        <img
          className="object rotateme"
          src="assets/img/objects/blog_shape02.png"
          width="66"
          style={{ right: "9%", bottom: "23%" }}
          alt="Object"
        />
      </section> */}

      <section className="newsletter-area section-py-55 tw-py-24">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-6 col-lg-4">
              <div className="newsletter__img-wrap">
                <div className="newsletter__img" data-aos="fade-right">
                  <img src="assets/img/others/newsletter.png" alt="img" />
                </div>
                <div className="newsletter__content">
                  <h4 className="title">
                    Join <br /> Our Newsletters
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="newsletter__form">
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter your email"
                  required
                  onChange={handleEmailChange}
                />
                <button type="submit" onClick={handleSubscribe}>
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {subscribed && (
        <div className="lg:tw-w-[400px] tw-w-[95%] tw-fixed tw-shadow-2xl tw-z-[9999] tw-h-auto tw-bg-[#124412] tw-px-[20px] tw-py-3 tw-top-[10px] lg:tw-right-5 tw-right-2 tw-rounded-[10px] tw-text-white tw-flex tw-justify-between tw-items-center">
          Email Subscribed Successfully!{" "}
          <button
            className="btn-danger tw-rounded"
            onClick={() => {
              setSubscribed(false);
            }}>
            Close
          </button>
        </div>
      )}
    </main>
  );
};

export default Home;
