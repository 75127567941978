import React, { useState } from "react";
import Modal from "react-modal";
import second from "../../assets/side_image.jpg";
import close from "../../assets/close.svg";

const SummerModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
        },
      }}>
      <div className="tw-w-full lg:tw-w-[850px] tw-h-[500px] lg:tw-h-[510px] tw-bg-center tw-flex tw-flex-col tw-justify-center tw-items-center relative">
        <div className="tw-w-full tw-flex tw-justify-end tw-cursor-pointer lg:mt-[-100px] tw-top-12 lg:tw-top-[40px] tw-right-4 lg:tw-right-[40px] tw-absolute">
          <img src={close} onClick={closeModal} alt="close" />
        </div>
        <div className="tw-w-full tw-grid tw-grid-col-1 lg:tw-grid-cols-2 tw-gap-y-7 lg:tw-gap-y-0 tw-items-center">
          <div className="text-center tw-text-base lg:tw-text-xl">
            <div className="tw-font-bold tw-text-[24px] lg:tw-text-[32px] tw-mb-3 lg:tw-mb-7">
              Summer Bootcamp!!!
            </div>
            <div>2024 Summer Bootcamp is now open </div>
            <div className="tw-text-[#025EE6] lg:tw-mb-2">
              For Just ₦80,000.
            </div>
            <div>
              <b>BATCH A</b> starts, 29th July - 16th August.{" "}
            </div>
            <div>
              <b>BATCH B</b> starts, 19th July - 6th September.
            </div>
            <a
              href="https://summer-class.stemfocus.net/register"
              className="btn tw-mt-4 lg:tw-mt-[54px]">
              Enroll Now
            </a>
          </div>
          <div className="tw-flex tw-justify-center">
            <img
              src={second}
              className="tw-rounded-full tw-overflow-hidden lg:tw-w-[300px] lg:tw-h-[300px] tw-w-[150px] tw-h-[150px]"
              alt="Summer Lesson"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SummerModal;
