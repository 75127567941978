import React from "react";
import BreadCrumb from "../common/BreadCrumb";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import loading from "../../images/common/loading.svg";
import loading_white from "../../images/common/loading_white.svg";
import axios from "axios";

const Checkout = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showError, setShowError] = useState(false);

  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [price, setPrice] = useState("");
  const [couponLoading, setCouponLoading] = useState(false);
  const [advancedCourse, setAdvancedCourse] = useState([]);
  const [standardCourse, setStandardCourse] = useState([]);

  useEffect(() => {
    const data = {
      course_id: id,
    };

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/view-course`;

    axios
      .post(endpointUrl, data)
      .then((response) => {
        console.log("data", response.data.data);
        setStandardCourse(response.data.data.standard);
        setAdvancedCourse(response.data.data.advanced);
        setSelectedCourse(response.data.data.standard);
        setDiscounted(response.data.data.standard.price);
        setPrice(response.data.data.standard.price);
        setLoadingPage(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          window.location.href = "/not-found";
        }
        console.error("Error:", error);
      });
  }, [id]);

  const [discounted, setDiscounted] = useState("");

  const thePostingFunction = (e, price, name, email, phone, id) => {
    e.preventDefault();

    console.log(id);

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer sk_live_a3cb27f3d8df07fa6ce5d26c7c218e71582d06bc`,
      },
    };

    axios
      .post(
        "https://api.paystack.co/transaction/initialize",
        {
          amount: price * 100,
          email: email,
          callback_url: "https://unicconacademy.com/register/success",
          metadata: {
            custom_fields: [
              {
                name: name,
                email: email,
                phone: phone,
                value: id,
              },
            ],
          },
        },
        config
      )
      .then((res) => {
        console.log("posting data", res);
        //window.open(res.data.data.url, "_blank");
        window.location.href = res.data.data.authorization_url;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const validatePhone = (input) => {
    const phoneRegex = /^\+?[0-9]*$/;
    return phoneRegex.test(input);
  };

  const handleBuyNowClick = (e) => {
    e.preventDefault();

    if (!name || !email || !phone) {
      setShowError(true);
    } else {
      setShowError(false);
      handleEnrollNow(e);
      //thePostingFunction(e, discounted, name, email, phone);
    }
  };

  const handleEnrollNow = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/submit-transaction`,
        {
          name: name,
          email: email,
          course: selectedCourse.title,
          course_type:
            selectedCourse.course_type_id === 1 ? "Standard" : "Advanced",
          course_price: discounted,
          coupon_code: coupon ? coupon : "",
          phone: phone,
        }
      );
      if (response.data.success) {
        setName("");
        setEmail("");
        setPhone("");

        thePostingFunction(
          event,
          discounted,
          name,
          email,
          phone,
          response.data.data.transaction_id
        );
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const [coupon, setCoupon] = useState("");

  const handleInputChange = (event) => {
    setCoupon(event.target.value);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageClass, setErrorMessageClass] = useState("error");

  const handleApplyCoupon = async (event) => {
    event.preventDefault();
    setCouponLoading(true);
    if (coupon === "") {
      setCouponLoading(false);
      setErrorMessage(`Coupon cannot be empty`);
      setErrorMessageClass("error");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/apply-coupon`,
        {
          coupon: coupon,
        }
      );

      const course_type =
        selectedCourse.course_type_id === 1 ? "Standard" : "Advanced";

      if (
        response.data.course === selectedCourse.title &&
        course_type === response.data.course_type
      ) {
        setCouponLoading(false);
        setErrorMessage(`Coupon Accepted`);
        setErrorMessageClass("sucess");

        const price = discounted - response.data.after_coupon_price;

        setDiscounted(price);

        setName(response.data.name);
        setEmail(response.data.email);
        setPhone(response.data.phone);
      } else {
        setCouponLoading(false);
        console.log(`This coupon can only be used for ${response.data.course}`);
        setErrorMessageClass("error");
        setErrorMessage(
          `This coupon can only be used for ${response.data.course}`
        );
      }
    } catch (error) {
      setCouponLoading(false);
      console.log("error:", error);
      setErrorMessageClass("error");
      setErrorMessage("Invalid Coupon try again");
    }
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-mb-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return (
    <main className="main-area fix">
      <BreadCrumb name="Checkout" location="Checkout" />

      <section className="contact-area section-py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-info-wrap tw-border-y-2 tw-border-0 tw-border-solid tw-border-8cc1c1 tw-pt-3">
                <div className="tw-w-full tw-flex tw-justify-between tw-items-center tw-font-bold tw-text-lg tw-py-12 tw-gap-x-3">
                  <div>
                    <div>{selectedCourse.title}</div>
                    <div className="tw-text-sm tw-font-normal">Full Course</div>
                  </div>
                  <div className="tw-font-normal">
                    <div>{selectedCourse.duration}</div>
                    <div className="tw-text-sm tw-font-normal">Duration</div>
                  </div>
                  <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-3">
                    <div>
                      <div>&#x20A6;{price.toLocaleString()}</div>
                      <div className="tw-text-sm tw-font-normal">Amount</div>
                    </div>
                    <a
                      className="btn-sm tw-text-sm btn tw-cursor-pointer"
                      title="Remove"
                      href="/pricing"
                    >
                      X
                    </a>
                  </div>
                </div>
                <div className="tw-flex tw-justify-end tw-font-bold tw-text-lg">
                  Subtotal: &#x20A6;{price.toLocaleString()}
                </div>
              </div>
              <div className="tw-flex tw-justify-end">
                <div className="tw-mt-12 tw-w-[300px] tw-h-[39px] tw-rounded-[10px] tw-overflow-hidden tw-border tw-border-solid  tw-border-[#1363df]">
                  <input
                    type="text"
                    className="tw-w-[70%] tw-h-[inherit] tw-border-0  tw-pl-2"
                    placeholder="Coupon code"
                    value={coupon}
                    onChange={handleInputChange}
                  />
                  <button
                    onClick={handleApplyCoupon}
                    className="tw-w-[30%] tw-h-[inherit] tw-text-[#ffffff] hover:tw-text-[#ffffff] tw-bg-[#1363df] hover:tw-bg-[#082a5e] border-0"
                  >
                    <span className="tw-flex tw-justify-center tw-items-center">
                      <span>Apply</span>{" "}
                      {couponLoading && <img src={loading_white} alt="gha" />}
                    </span>
                  </button>
                </div>
              </div>
              <div className="tw-flex tw-justify-end">
                {errorMessage && (
                  <div className={errorMessageClass}>{errorMessage}</div>
                )}
              </div>
              <div className="tw-flex tw-justify-end tw-font-bold tw-text-xl tw-mt-6">
                Total: ₦{discounted.toLocaleString()}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-form-wrap tw-h-auto tw-pb-5 tw-mb-3">
                <h4 className="title">Payment Information</h4>
                <form id="contact-form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-grp">
                        <input
                          type="text"
                          value={name}
                          name="fullname"
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name *"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-grp">
                        <input
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder="E-mail *"
                          required
                        />
                        {email && !validateEmail(email) && (
                          <span className="error"> Invalid email format</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-grp">
                        <input
                          name="phonenumber"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="number"
                          placeholder="Phone *"
                          required
                        />
                        {phone && !validatePhone(phone) && (
                          <span className="error">
                            Invalid phone number format
                          </span>
                        )}
                      </div>
                    </div>
                    {showError && (
                      <p className="error">
                        Please fill in all fields before proceeding.
                      </p>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn"
                    onClick={handleBuyNowClick}
                  >
                    Submit Payment
                  </button>
                </form>
                <p className="ajax-response mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Checkout;
